<template>
  <b-container fluid class="my-3">
    <b-skeleton-wrapper :loading="ProductsLoading">
      <template #loading>
        <b-container fluid class="my-4">
          <SearchLoader></SearchLoader>
        </b-container>
      </template>
      <b-row>
        <b-col sm="3" v-if="!ProductsLoading && items != null">
          <SidePage
            @Change-Search="ChangeSearch"
            :max="maxProdPrice"
          ></SidePage>
        </b-col>

        <b-col sm="9">
          <b-card
            class="justify-content-center bg-white my-2"
            v-if="SearchEmpty"
          >
            <div class="text-center mb-5">
              <h2 style="color: #f6601a" class="my-4 mb-2">
                {{ $t("SearchErr") }}
              </h2>
              <h4>{{ $t("SearchErr_1") }}</h4>
            </div>
          </b-card>

          <b-row v-if="!ProductsLoading" id="my-cards">
            <ProdCard
              v-for="Product in itemsForList"
              v-bind:key="Product.id"
              v-bind:Product="Product"
              :per-page="perPage"
              :current-page="currentPage"
              class="col-sm-3 col-6 p-1"
            ></ProdCard>
          </b-row>
          <b-row v-if="waitReady" align-h="center">
            <b-pagination
              class="my-3 p-0"
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="my-cards"
            ></b-pagination>
          </b-row>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ProdCard from "../components/items/ProdCard.vue";
import SearchLoader from "../components/widgets/SearchLoader.vue";
import SidePage from "../components/widgets/SidePage.vue";
import i18n from "../main.js";

export default {
  computed: {
    ...mapGetters(["Categories", "Tags"]),
    itemsForList() {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  components: {
    SidePage,
    ProdCard,
    SearchLoader,
  },
  watch: {
    currentPage() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 300);
    },
    items() {
      this.ProductsLoading = false;
    },
  },
  methods: {
    ChangeSearch(sform) {
      this.ProductsLoading = true;
      this.searchform = sform;
      this.DoSearch();
    },
    DoSearch: function() {
      this.$Progress.start();

      var curr = this.$cookies.get("wmc_current_currency");
      var ship = this.$cookies.get("shipCountry");
      var url =
        process.env.VUE_APP_DEVBASEURL +
        "/ProdAll/" +
        this.searchform.limit +
        "/" +
        curr +
        "/" +
        ship +
        "/" +
        this.searchform.condition +
        "/" +
        this.searchform.maxprice +
        "/" +
        this.searchform.minprice +
        "/" +
        this.searchform.highRate +
        "/" +
        this.searchform.lowRate;
      axios.get(url).then((resp) => {
        if (resp.data.status) {
          this.items = resp.data.items[0];
          this.maxProdPrice = resp.data.items[1];

          this.$Progress.finish();
          this.waitReady = true;
          if (this.items.length == 0) {
            this.SearchEmpty = true;
          } else {
            this.SearchEmpty = false;
          }
        }
      });
    },
  },
  data() {
    return {
      perPage: 8,
      currentPage: 1,
      searchform: {
        minprice: 0.1,
        maxprice: 2000,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      maxProdPrice: 0,
      sortByOptions: {
        Old: i18n.t("Old"),
        New: i18n.t("New"),
        PriceHighToLow: i18n.t("Price High To Low"),
        PriceLowToHigh: i18n.t("Price Low To High"),
      },
      limitOptions: {
        20: "20 منتج",
        50: "50 منتج",
        100: "100 منتج",
        10000: "جميع المنتجات",
      },
      ProductsLoading: true,
      SearchEmpty: false,
      items: [],
      waitReady: false,
      customLabels: {
        first: this.$t("first"),
        last: this.$t("last"),
        previous: this.$t("previous"),
        next: this.$t("next"),
      },
    };
  },
  created() {
    this.DoSearch();
    this.$gtag.event("E_AllProducts", { method: "Google" });
  },
  metaInfo: {
    title: "Products",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "قم بتصفح كامل منتجات الموقع من ورق تغليف وشرائط وبوكسات ومزهريات وأكياس وغيرها بأفضل الأسعار التنافسية .",
      },
    ],
  },
};
</script>

<style>
.pagination {
  margin-right: auto !important;
  margin-left: auto !important;
}
li.first {
  display: none !important;
}
li.last {
  display: none !important;
}
.Treelist {
  list-style: none;
  text-align: right;
  margin: 0 18px;
}
</style>
