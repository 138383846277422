<template>
    <div class="row">
        <ProdListLoader></ProdListLoader>
        <ProdListLoader></ProdListLoader>
        <BannerLoader></BannerLoader>
        <ProdListLoader></ProdListLoader>
        <ProdListLoader></ProdListLoader>
        <BannerLoader></BannerLoader>
    </div>
</template>

<script>

import ProdListLoader from '../widgets/PordListLoader.vue';
import BannerLoader from '../widgets/BannerLoader.vue';


export default {
    components:{
        ProdListLoader,
        BannerLoader
    }
}
</script>

<style>

</style>